import Flex from 'components/Flex';
import Spinner from 'components/OverlaySpinner/Spinner';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

interface IOverlaySpinner {
  className?: string;
  visible: boolean;
}

const OverlaySpinner: FunctionComponent<IOverlaySpinner> = ({ className, visible }) =>
  visible ? (
    <Container justifyContent="center" alignItems="center" className={className}>
      <Spinner />
    </Container>
  ) : (
    <></>
  );

export default OverlaySpinner;

const Container = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;
`;
